import { useState, useEffect } from "react";
import axios from "axios";
import cancelImg from "../../assets/img/question.png";
import { api_url } from "../../utils/config";
import { ReactTitle } from "react-meta-tags";
import { useHistory } from "react-router";
import { formatPrice } from "../../helper";

const Cancel = () => {
  let history = useHistory();

  const queryParams = new URLSearchParams(window.location.search);
  const orderId = queryParams.get("order_id");

  const [orderDetail, setOrderDetail] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getOrderDetail = async () => {
      setLoading(true);
      try {
        let response = await axios.get(
          `${api_url}/v1/payment/instant-finish/${orderId}`
        );
        if (orderId !== null) {
          if (!response.data.error) {
            setOrderDetail(response.data.results.detail_order);
            setLoading(false);
          } else {
            history.push('/error');
          }
        } else {
          setOrderDetail("");
          setLoading(false);
          history.push('/error');
        }
      } catch (err) {
        setLoading(true);
        return err.message;
      }
    };
    getOrderDetail();
  }, [history, orderId]);

  return (
    <main id="Cancel">
      <ReactTitle title="Canceled - Codepolitan Payment" />
      <div className="container-fluid">
        <div className="row" style={{ minHeight: "100vh" }}>
          <div className="col-lg-7">
            <div className="py-5 px-lg-5">
              <h1 className="main-title">Codepolitan Payment</h1>
              <div className="row mt-5 mb-3">
                <div className="col-2">
                  <img className="img-fluid" src={cancelImg} alt="Cancel" />
                </div>
                <div className="col-auto">
                  <label className="mt-lg-3">
                    Order id: {loading ? "Loading..." : orderId}
                  </label>
                  <h3 className="header-title">
                    <strong>
                      Hi{" "}
                      {loading ? "Loading..." : orderDetail.guest_name + '!'}
                    </strong>
                  </h3>
                </div>
              </div>
              <div className="row mb-3">
                <div className="col">
                  <div className="card">
                    <div className="card-body">
                      <h4 className="card-title">
                        Yah, kenapa dibatalin?
                      </h4>
                      <p className="card-text">
                        Sayang sekali, pesanan kakak sudah kami batalkan di sistem. Apakah ada penawaran kami yang kurang? Jika kakak masih ragu
                        membeli, kami terbuka lebar untuk ngobrol bareng tentang produk kami, bisa chat kak Putri nih
                      </p>
                      <a className="btn btn-success btn-sm" href="https://wa.me/6285174469460"><i className="fab fa-fw fa-whatsapp" /> Chat Putri</a>
                      <p className="card-text mt-5">
                        Ditunggu ya!
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mb-3 d-none d-lg-block">
                <div className="col">
                  <a
                    className="btn btn-primary btn-lg px-4"
                    href="https://codepolitan.com/library"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Lihat Produk Lain
                  </a>{" "}
                  <span className="ms-3">
                    Butuh bantuan?{" "}
                    <a className="link" href="https://wa.me/6285174469460">
                      Hubungi kami
                    </a>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-5 bg-light border-start border-top border-2">
            <div className="py-5 px-lg-5">
              <div className="row">
                <div className="col-3">
                  {loading ? (
                    "Loading..."
                  ) : (
                    <img
                      className="img-fluid rounded"
                      src={orderDetail.product_image}
                      alt={orderDetail.product_name}
                    />
                  )}
                </div>
                <div className="col-9">
                  <h3 className="product-title">
                    {loading ? "Loading..." : orderDetail.product_name}
                  </h3>
                  <p>
                    Tipe: {loading ? "Loading..." : orderDetail.product_type}
                  </p>
                </div>
              </div>
              <hr />
              <div className="row g-3">
                <div className="col">
                  <p>Harga</p>
                  <p>Diskon</p>
                </div>
                <div className="col text-end">
                  <p>
                    Rp.{" "}
                    {loading
                      ? "Loading..."
                      : formatPrice(orderDetail.retail_price) || "0"}
                  </p>
                  <p>
                    Rp.{" "}
                    {loading
                      ? "Loading..."
                      : formatPrice(orderDetail.discount) || "0"}
                  </p>
                </div>
              </div>
              <hr />
              <div className="row">
                <div className="col">
                  <p>Total</p>
                </div>
                <div className="col text-end">
                  <p>
                    Rp.{" "}
                    <span className="total-price">
                      {loading
                        ? "Loading..."
                        : formatPrice(orderDetail.total_price) || "0"}
                    </span>
                  </p>
                </div>
              </div>
              <div className="row mb-3 d-block d-lg-none">
                <div className="col-auto">
                  <div className="d-grid">
                    <a
                      className="btn btn-primary btn-lg px-4"
                      href="https://codepolitan.com/library"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Lihat Produk Lain
                    </a>
                  </div>
                </div>
                <div className="col-auto mt-3">
                  <p>
                    Butuh bantuan?{" "}
                    <a className="link" href="https://wa.me/6285174469460">
                      Hubungi kami
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default Cancel;
