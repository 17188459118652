import { useState, useEffect, createRef } from "react";
import axios from "axios";
import successImg from "../../assets/img/checklist.png";
import { api_url } from "../../utils/config";
import { ReactTitle } from "react-meta-tags";
import { useHistory } from "react-router";
import { formatPrice } from "../../helper";
import ReactToPdf from "react-to-pdf";

const Finish = () => {
  let history = useHistory();

  const queryParams = new URLSearchParams(window.location.search);
  const ref = createRef();
  const orderId = queryParams.get("order_id");

  const [orderDetail, setOrderDetail] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getOrderDetail = async () => {
      setLoading(true);
      try {
        let response = await axios.get(
          `${api_url}/v1/payment/instant-finish/${orderId}`
        );
        if (orderId !== null) {
          if (!response.data.error) {
            setLoading(false);
            setOrderDetail(response.data.results.detail_order);
          } else {
            history.push('/error');
          }
        } else {
          setOrderDetail("");
          setLoading(false);
          history.push('/error');
        }
      } catch (err) {
        setLoading(true);
        return err.message;
      }
    };
    getOrderDetail();

  }, [history, orderId]);

  useEffect(() => {
    if (typeof window !== 'undefined' && orderId && orderDetail?.total_price) {
      // Gtag Fire
      window.gtag('event', 'conversion', {
        'send_to': 'AW-16492360410/7HunCP3M0ZsZENrllbg9',
        'value': orderDetail?.total_price,
        'currency': 'IDR',
        'transaction_id': orderId
      });

      // Pixel Fire
      window.fbq('track', 'Purchase', { currency: "IDR", value: orderDetail?.total_price });

      // Tiktok Fire
      window.ttq.track('InitiateCheckout', {
        content_type: 'product',
        content_id: orderId,
        value: orderDetail?.total_price,
        currency: 'IDR'
      });
    };
  }, [orderDetail?.total_price, orderId]);

  const options = {
    orientation: 'landscape',
    unit: 'in',
    format: [7.5, 15.5]
  };

  return (
    <main id="Finish" ref={ref}>
      <ReactTitle title="Finish - Codepolitan Payment" />
      <div className="container-fluid">
        <div className="row" style={{ minHeight: "100vh" }}>
          <div className="col-lg-7">
            <div className="py-5 px-lg-5">
              <h1 className="main-title">Codepolitan Payment</h1>
              <div className="row mt-5 mb-3 mt-lg-0 mb-lg-0">
                <div className="col-2 my-auto">
                  <img className="img-fluid" src={successImg} alt="Sukses" />
                </div>
                <div className="col-auto my-auto">
                  <label>
                    Order id: {loading ? "Loading..." : orderId}
                  </label>
                  <h3 className="header-title">
                    <strong>
                      Thank you{" "}
                      {loading ? "Loading..." : orderDetail.guest_name + '!'}
                    </strong>
                  </h3>
                </div>
                <div className="col-auto ms-auto d-none d-lg-block">
                  <img style={{ height: '10em', width: '10em' }} src={`https://quickchart.io/qr?text=https://pay.codepolitan.com/status/finish?order_id=${orderId}`} alt="QRCode" />
                </div>
              </div>
              <div className="row mb-3">
                <div className="col">
                  <div className="card">
                    <div className="card-body">
                      <h4 className="card-title">
                        Pesanan Kakak berhasil diproses!
                      </h4>
                      {orderDetail.product_type === "course" || orderDetail.product_type === "path" || orderDetail.product_type === "scholarship" || orderDetail.product_type === "membership" ? (
                        <>
                          <p className="card-text">
                            Yeay, pembayaran kakak sudah kami terima. Silahkan periksa Kode Voucher yang kami kirimkan ke Whatsapp dan Email lalu kakak bisa mengaktifkannya dengan mudah di halaman Redeem Voucher.
                          </p>
                          <a className="btn btn-primary btn-sm" href="https://codepolitan.com/login?callback=https://dashboard.codepolitan.com/user/redeem">Redeem Voucher Sekarang</a>
                        </>
                      ) : ''}
                      {orderDetail.product_type !== "course" && orderDetail.product_type !== "path" && orderDetail.product_type !== "scholarship" && orderDetail.product_type !== "membership" && orderDetail.product_type !== "workshop" && (
                        <>
                          <p className="card-text">
                            Yeay, pembayaran kakak sudah kami terima. Produk yang kaka pesan akan kami kirimkan segera. Silahkan ditunggu ya!
                          </p>
                          <p className="card-tex">
                            Mohon abaikan email dan whatsapp konfirmasi voucher yang kami kirimkan.
                          </p>
                        </>
                      )}
                      {orderDetail.product_type === "workshop" && (
                        <>
                          <p className="card-text">
                            Yeay, pembayaran kakak sudah kami terima. Selanjutnya mohon tunggu arahan mentor atau admin kami ya kak. Kakak sudah resmi terdaftar sebagai peserta workshop / bootcamp kami. Semangat ya belajarnya!
                          </p>
                          <p className="card-tex">
                            Mohon abaikan email dan whatsapp konfirmasi voucher yang kami kirimkan.
                          </p>
                        </>
                      )}
                      <p className="card-text mt-5">
                        Terima kasih.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mb-3 d-none d-lg-block">
                <div className="d-flex gap-3">
                  <a
                    className="btn btn-primary"
                    href="https://codepolitan.com/library"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Lihat Produk Lain
                  </a>
                  <ReactToPdf targetRef={ref} options={options} filename={`bukti-pembayaran-${orderId}.pdf`}>
                    {({ toPdf }) => (
                      <button
                        onClick={toPdf}
                        className="btn btn-warning"
                      >
                        Cetak Bukti Pembayaran
                      </button>
                    )}
                  </ReactToPdf>
                  <span className="my-auto ms-2">
                    Butuh bantuan?{" "}
                    <a className="link" href="https://wa.me/6285174469460">
                      Hubungi kami
                    </a>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-5 bg-light border-start border-top border-2">
            <div className="py-5 px-lg-5">
              <div className="row">
                <div className="col-3">
                  {loading ? (
                    "Loading..."
                  ) : (
                    <img
                      onError={(e) => e.target.src = "https://placehold.co/600x400?text=CODEPOLITAN"}
                      className="img-fluid rounded"
                      src={orderDetail.product_image}
                      alt={orderDetail.product_name}
                    />
                  )}
                </div>
                <div className="col-9">
                  <h3 className="product-title">
                    {loading ? "Loading..." : orderDetail.product_name}
                  </h3>
                  <p>
                    Tipe: {loading ? "Loading..." : orderDetail.product_type}
                  </p>
                </div>
              </div>
              <hr />
              <div className="row g-3">
                <div className="col">
                  <p>Harga</p>
                  <p>Diskon</p>
                </div>
                <div className="col text-end">
                  <p>
                    Rp.{" "}
                    {loading
                      ? "Loading..."
                      : formatPrice(orderDetail.retail_price) || "0"}
                  </p>
                  <p>
                    Rp.{" "}
                    {loading
                      ? "Loading..."
                      : formatPrice(orderDetail.discount) || "0"}
                  </p>
                </div>
              </div>
              <hr />
              <div className="row">
                <div className="col">
                  <p>Total</p>
                </div>
                <div className="col text-end">
                  <p>
                    Rp.{" "}
                    <span className="total-price">
                      {loading
                        ? "Loading..."
                        : formatPrice(orderDetail.total_price) || "0"}
                    </span>
                  </p>
                </div>
              </div>
              <div className="mb-3 d-lg-none">
                <div className="d-grid gap-2">
                  <a
                    className="btn btn-primary btn-lg px-4"
                    href="https://codepolitan.com/library"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Lihat Produk Lain
                  </a>
                  <span className="mt-5">
                    Butuh bantuan?{" "}
                    <a className="link" href="https://wa.me/6285174469460">
                      Hubungi kami
                    </a>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default Finish;
